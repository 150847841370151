import { useEffect, useRef, useState } from 'react';

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      // document.addEventListener("click", listener);
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        // document.removeEventListener("click", listener);
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

function Menu({ setCurrent, setIsMenuVisible }) {
  const goTo = target => () => {
    setCurrent(target);
    setIsMenuVisible(false);
    window.scrollTo(0, 0);
  };
  return (
    <div className="relative flex flex-wrap h5">
      <div className="w-50 flex items-center justify-center" onClick={goTo('Welcome')}>
        Welcome
      </div>
      <div className="w-50 flex items-center justify-center" onClick={goTo('Register')}>
        Register
      </div>
      <div className="w-50 flex items-center justify-center" onClick={goTo('Information')}>
        Information
      </div>
      <div className="w-50 flex items-center justify-center" onClick={goTo('Contact')}>
        Contact
      </div>
      <div className="absolute top-0 right-0 white w1 h1 mt1 mr1 f4 pointer" onClick={() => setIsMenuVisible(false)}>&times;</div>
    </div>
  );
}

function Welcome() {
  return (
    <>
      <p>Dear family,</p>
      <p>We are thrilled to announce the final details and begin registration for the 2023 Kubba Reunion!</p>
      <p>The next reunion will take place between <b>August 4 - 6, 2023</b>, at the <b>Elite World Hotel</b> in <b>Sapanca, Turkey</b>.</p>
      <img src="elite-world-sapanca.jpg" alt="Elite World Hotel, Sapanca, Turkey" />
      <p>The Kubba family reunion is a gathering for descendents of Hajj Ma’roof Kubba, and relatives with parents or grandparents from the Kubba family.</p>
      <p>We hope you can all come and join us for another wonderful family gathering!</p>
    </>
  )
}

function NameEditList({ list, names, setNames }) {
  const setAtIndex = (index, name) => setNames({
    ...names,
    [list]: [
      ...names[list].slice(0, Math.max(0, index)),
      name,
      ...names[list].slice(Math.min(index + 1, names[list].length)),
    ],
  });
  const removeAtIndex = (index) => {
    names[list].splice(index, 1);
    const newList = [
      ...names[list],
      // ...names[list].slice(Math.min(index, names[list].length)),
    ];
    return setNames({
      ...names,
      [list]: newList,
    });
  };
  const addName = () => setNames({
    ...names,
    [list]: [
      ...names[list],
      ''
    ],
  });
  return (
    <>
      {
        names[list].map((name, index) => (
          <div className="w-100 w-50-ns flex items-center mt1 mb2" key={index}>
            <input placeholder="Full name including father's name" className="flex-grow-1 pa1 br2 b--transparent" type="text" onChange={e => setAtIndex(index, e.target.value)} value={name} />
            <span className="br-100 ba b--dark-red bw1 w2 h2 overflow-hidden flex-shrink-1 ml3 pointer dark-red inline-flex items-center justify-around" onClick={() => removeAtIndex(index)}>&times;</span>
          </div>
        ))
      }
      <div className="pointer dark-green b pa2 " onClick={addName}>+ Add { !names[list].length ? 'one' : 'another' }</div>
    </>
  );
}

function Register({ setCurrent, setNames, names, supportAmount, setSupportAmount }) {
  const supportAmountInput = useRef();
  const supportAmountNoneRadio = useRef();
  const supportAmountOtherRadio = useRef();
  return (
    <>
      <p><b className="f4">Registration and Payment</b></p>
      <p>The Kubba family reunion is a gathering for descendents of Hajj Ma'roof Kubba and their close relatives.</p>
      <p>Payment below includes dinner on Friday and the gala dinner on Saturday, and goes towards covering all the group activities and expenses, such as the photographer and DJ.</p>
      <p>Payment does <b>not include</b> your hotel room, breakfast/lunch or transportation to or from the hotel. Please visit our <span className="pointer cool-purple underline" onClick={() => setCurrent('Information')}>hotel information page</span> for those prices.</p>
      <p>Please enter the full names (including father's names) of the attendees you would like to register and pay for below.</p>

      <div className="mv3 pa3 bg-light-silver br2 b--cool-border ba bw1">
        <p><b className="f4 cool-purple">Attendees</b></p>
        <p><b>Adults and Kids Ages 12+ (USD $165)</b></p>
        <NameEditList list="adults" names={names} setNames={setNames} />
        <p className="mt4"><b>Kids Ages 5-11 (USD $80)</b></p>
        <NameEditList list="olderChildren" names={names} setNames={setNames} />
        <p className="mt4"><b>Kids 4 and Under (free)</b></p>
        <NameEditList list="youngerChildren" names={names} setNames={setNames} />

        <div className="bt b--cool-border cool-shadow mv4" />

        <p><b className="f4 cool-purple">Additional Support</b></p>
        <p>In order to keep the event price as low as possible, while still providing an amazing experience for our family, we rely on generous support from family members who are able and willing to provide additional funds. This support is completely optional; any amount would be greatly appreciated and would help us put together a better event!</p>
        <p className="mv3"><input type="radio" name="supportlevel" value="none" defaultChecked={supportAmount === 0} id="none" onChange={() => setSupportAmount(0)} ref={supportAmountNoneRadio} /> <label htmlFor="none"><span className="dib w4">None</span> $0</label></p>
        <p className="mv3"><input type="radio" name="supportlevel" value="200" id="200" defaultChecked={supportAmount === 200} onChange={() => setSupportAmount(200)} /> <label htmlFor="200"><span className="dib w4">Supporter</span> $200</label></p>
        <p className="mv3"><input type="radio" name="supportlevel" value="1000" id="1000" defaultChecked={supportAmount === 1000} onChange={() => setSupportAmount(1000)} /> <label htmlFor="1000"><span className="dib w4">Sponsor</span> $1,000</label></p>
        <p className="mt3"><input type="radio" name="supportlevel" value="2500" id="2500" defaultChecked={supportAmount === 2500} onChange={() => setSupportAmount(2500)} /> <label htmlFor="2500"><span className="dib w4">Patron</span> $2,500</label></p>
        <p className="flex items-center">
          <input ref={supportAmountOtherRadio} type="radio" name="supportlevel" value="other" defaultChecked={!([0, 200, 1000, 2500].includes(supportAmount))} onChange={() => setSupportAmount(Math.max(0, parseInt(supportAmountInput.current.value, 10)))} className="mr1" />
          <label htmlFor="othervalue" onClick={() => {
            supportAmountOtherRadio.current.checked = true;
            supportAmountInput.current.focus();
          }} className="dib w4 nowrap">Other amount:</label>
          &nbsp;
          $<input
          placeholder=""
          className="ml1 pa1 br2 b--transparent w4 "
          type="number"
          ref={supportAmountInput}
          id="othervalue"
          onChange={e => {
            const newAmount = Math.max(0, parseInt(e.target.value, 10));
            setSupportAmount(newAmount);

            if (newAmount > 0) {
              supportAmountNoneRadio.current.checked = false;
              supportAmountOtherRadio.current.checked = true;
            } else {
              supportAmountOtherRadio.current.checked = false;
              supportAmountNoneRadio.current.checked = true;
            }
          }}
        />
        </p>

        <div className="bt b--cool-border cool-shadow mv4" />

        <p><span onClick={() => {
          setNames({
            adults: names.adults.map(name => name.trim()).filter(Boolean),
            olderChildren: names.olderChildren.map(name => name.trim()).filter(Boolean),
            youngerChildren: names.youngerChildren.map(name => name.trim()).filter(Boolean),
          });
          setCurrent('RegisterConfirm');
        }} className="br2 bg-dark-green white pa2 w4 tc pointer">Continue »</span></p>
      </div>
    </>
  )
}

function RegisterConfirm({ names, setCurrent, supportAmount }) {
  const paypalButtonContainer = useRef();
  const paypalButtonContainerRendered = useRef(false);

  const total = names.adults.length * 165
    + names.olderChildren.length * 80
    + supportAmount;

  const supportTitle = supportAmount >= 2500
    ? 'Patron'
    : supportAmount >= 1000
      ? 'Sponsor'
      : supportAmount >= 200
        ? 'Supporter'
        : supportAmount > 0
          ? 'Helper'
          : 'None';


  useEffect(() => {
    if (!paypalButtonContainerRendered.current) {
      paypalButtonContainerRendered.current = true;
      window.paypal.Buttons({
        onApprove: function (data, actions) {
          actions.order.capture().then(() => setCurrent('RegisterThanks'));
        },
        createOrder: function(data, actions) {
          // Set up the transaction
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: total + '',
                  currency_code: 'USD',
                  breakdown: {
                    item_total: {
                      currency_code: 'USD',
                      value: total + '',
                    },
                  },
                },
                items: [
                  ...(names.adults.map(name => ({
                    name: name,
                    quantity: 1,
                    unit_amount: {
                      currency_code: 'USD',
                      value: '165',
                    },
                    category: 'DONATION',
                    description: 'Registration for adult or child 12+',
                  }))),
                  ...(names.olderChildren.map(name => ({
                    name: name,
                    quantity: 1,
                    unit_amount: {
                      currency_code: 'USD',
                      value: '80',
                    },
                    category: 'DONATION',
                    description: 'Registration for child 5-11',
                  }))),
                  ...(names.youngerChildren.map(name => ({
                    name: name,
                    quantity: 1,
                    unit_amount: {
                      currency_code: 'USD',
                      value: '0',
                    },
                    category: 'DONATION',
                    description: 'Registration for child under 5',
                  }))),
                  {
                    name: 'Additional Support',
                    quantity: 1,
                    unit_amount: {
                      currency_code: 'USD',
                      value: supportAmount,
                    },
                    category: 'DONATION',
                    description: supportTitle,
                  },
                ],
              },
            ],
            application_context: {
              shipping_preference: 'NO_SHIPPING'
            },
          });
        },
        style: {
          color: 'black',
          tagline: false,
        },
      }).render(paypalButtonContainer.current);
    }
  }, []);

  return (
    <div className="mw7">
      <p><b className="f4">Confirm Registration Information</b></p>
      <p>Please confirm that the information below is correct and complete:</p>

      <table className="collapse w-100" cellPadding={0} cellSpacing={0}>
        <tbody>
        <tr>
          <td colSpan={3} className="f4 cool-purple b pb3">
            Attendees
          </td>
        </tr>
        <tr>
          <td className="b">
            Adults and Kids Ages 12+
          </td>
          <td className="b">
            { (names.adults || []).length }
          </td>
          <td className="b tr">
            ${ (names.adults || []).length * 165 }
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="pb3">
            {(names.adults || []).join(', ')}
          </td>
        </tr>
        <tr>
          <td className="b">
            Kids Ages 5-11
          </td>
          <td className="b">
            { (names.olderChildren || []).length }
          </td>
          <td className="b tr">
            ${ (names.olderChildren || []).length * 80 }
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="pb3">
            {(names.olderChildren || []).join(', ')}
          </td>
        </tr>
        <tr>
          <td className="b">
            Kids 4 and Under
          </td>
          <td className="b">
            { (names.youngerChildren || []).length }
          </td>
          <td className="b tr">
            $0
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="pb3">
            {(names.youngerChildren || []).join(', ')}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="f4 cool-purple b pb3">
            Additional Support
          </td>
        </tr>
        <tr>
          <td className="b pb4">
            { supportTitle }
          </td>
          <td className="b pb4">
            1
          </td>
          <td className="b tr pb4">
            ${ supportAmount }
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="bt b--cool-border cool-shadow pb2" />
        </tr>
        <tr>
          <td className="b f3" colSpan={2}>
            Total
          </td>
          <td className="b f3 tr">
            ${ total }
          </td>
        </tr>
        </tbody>

      </table>

      <div className="bt b--cool-border cool-shadow mt2 mb4" />

      <p><span onClick={() => setCurrent('Register')} className="br2 bg-dark-green white pa2 w4 tc">« Edit</span></p>
      <p className="pv2">Click Edit to make any needed changes. If all of the information above looks correct, select your preferred payment option from the list below:</p>
      <div className="w-100" id="paypalButtonContainer" ref={paypalButtonContainer} />
    </div>
  )
}

function Information({ setCurrent }) {
  return (
    <>
      {/*<p>Info</p>*/}
      <p><b className="f4">Information</b></p>
      <p>The next reunion will take place between <b>August 4 - 6, 2023</b>, at the <b>Elite World Hotel</b> in <b>Sapanca, Turkey</b>.</p>
      <p className="f5 b underline">We highly recommend that you register for the event, book your hotel and book your flights as soon as possible!</p>
      <p className="pt3"><b className="f4">Airport</b></p>
      <p>
        <b>Nearest International Airport</b> Sabiha Gokcen International Airport (1h15m away)<br />
      </p>
      <p>
        <b>Nearest Domestic Airport</b> Cengiz Topel Airport (30m away)<br />
      </p>
      <p className="pt3"><b className="f4">Elite World Hotel</b></p>
      <p>The Elite World Hotel is where the main reunion events will take place, and there is a special discounted rate for reunion attendees.</p>
      <p className="lh-copy">Registration to the reunion is separate from the hotel booking, and needs to be completed on the <span className="pointer b cool-purple underline" onClick={() => setCurrent('Register')}>Register page</span> in order to attend the event. Booking rooms at the Elite World Hotel is <i>optional</i> for attendance of the reunion events, and a short list of nearby alternative hotel options are listed below. However, we do not have any special arrangements with the other hotels.</p>
      <p className="lh-copy">
        <b className="">Booking Information</b><br />
        Please call or e-mail (details below) to book your room(s) as soon as possible<br />
        Remember to <span className="b i">mention group code "Family Reunion 04-06 August"</span> to get our discounted prices.<br />
        The hotel will not charge you for the booking until May 2023.<br />
      </p>
      <p>
        <b>Hotel Room Prices</b> (per room per night)<br />
        Single 200 Euro<br />
        Double 220 Euro<br />
        Triple 310 Euro<br />
        <span className="lh-copy">Children under age of 7, free</span><br />
        Children ages 7 to 11, 70 Euro<br />
        <span className="lh-copy">Superior Room +20 Euro</span><br />
        Premium Room +30 Euro<br />
        <span className="lh-copy">These prices are a special rate for Kubba Reunion attendees, and the prices <b>include breakfast and VAT</b>. Note that the hotel will not charge you for the booking until May 2023.</span>
      </p>
      <p>
        <b>Website</b> <a target="_blank" rel="noreferrer" className="cool-purple" href="https://www.eliteworldhotels.com.tr/elite-world-sapanca-hotel.aspx">Official Hotel Website</a>
      </p>
      <p>
        <b>Hotel Contact</b> Mr. Onur Bas
      </p>
      <p>
        <b>Phone</b> <a className="cool-purple" href="tel:+902644151000">+90 264 415 1000</a>
      </p>
      <p>
        <b>E-mail</b> <a className="cool-purple" href="mailto:onur.bas@eliteworldhotels.com.tr?subject=Booking for 'Family Reunion 04-06 August'">onur.bas@eliteworldhotels.com.tr</a>
      </p>
      <p>
        <b>Important Note:</b> When calling or emailing to book your hotel room, make sure to <span className="b i">mention group code "Family Reunion 04-06 August"</span> to get our special discounted rate.
      </p>
      <div>
        <b>Address</b> (open on <a target="_blank" className="cool-purple" href="https://www.google.com/maps/place/Elite+World+Sapanca+Convention+%26+Wellness+Resort/@40.6889232,30.2961047,607m/data=!3m1!1e3!4m8!3m7!1s0x14ccafe38ed36289:0x8351bc1766152aa8!5m2!4m1!1i2!8m2!3d40.6883416!4d30.2948253!5m1!1e4" rel="noreferrer">Google Maps</a>)<br />
        Göl Mahallesi Koşuyolu Caddesi No:71<br />
        Sapanca / Sakarya - Türkiye<br />
      </div>
      <div className="mt4 flex flex-wrap">
        <div className="w-100 w-50-ns pr1-ns"><img src="elite-1.jpg" alt="Elite World Hotel, Sapanca, Turkey" className="br2 cool-shadow" style={{ filter: 'contrast(.95) brightness(1.2) saturate(.75)' }} /></div>
        <div className="w-100 w-50-ns pl1-ns"><img src="elite-2.jpg" alt="Elite World Hotel, Sapanca, Turkey" className="br2 cool-shadow" style={{ filter: 'contrast(.9) brightness(1.2) saturate(.75)' }} /></div>
        <div className="w-100 w-50-ns pr1-ns"><img src="elite-3.jpg" alt="Elite World Hotel, Sapanca, Turkey" className="br2 cool-shadow" style={{ filter: 'contrast(1) brightness(1.2) saturate(.75)' }} /></div>
        <div className="w-100 w-50-ns pl1-ns"><img src="elite-4.jpg" alt="Elite World Hotel, Sapanca, Turkey" className="br2 cool-shadow" style={{ filter: 'contrast(.9) brightness(1.2) saturate(.75)' }} /></div>
      </div>

      <p className="pt3"><b className="f4">Alternative Hotel Options</b></p>
      <p>Although we recommend the Elite World Hotel (information above), and have secured a discounted rate with them, there are also many other hotel options nearby. We do recommend doing your own research if you would like to go with a different hotel, but here are a couple of options to get you started on your search:</p>
      <p><a target="_blank" rel="noreferrer" className="cool-purple" href="https://hotelbonvie.com/">Hotel Bonvie</a> - Tekeler, Çevre Yolu Cd., 54100 Adapazarı/Sakarya, Turkey</p>
      <p><a target="_blank" rel="noreferrer" className="cool-purple" href="https://kartepekosku.com.tr/">Kartepe Köşkü</a> - Maşukiye, 41295 Kartepe/Kocaeli, Turkey</p>
    </>
  )
}

function RegisterThanks() {
  return (
    <>
      <p className="mt4">
        Thank you for registering!
      </p>
      <p>
        We have securely received your registration information and are excited to see you in August!
      </p>
    </>
  )
}

function Contact() {
  return (
    <>
      <p className="mt4 lh-copy">
        You can reach the <b>Elite World Hotel</b> in Sapanca, Turkey by phone at <a className="cool-purple" href="tel:+902644151000">+90 264 415 1000</a> (ask to speak to Mr. Onur Bas), or by email at <a className="cool-purple" href="mailto:onur.bas@eliteworldhotels.com.tr?subject=Booking for 'Family Reunion 04-06 August'">onur.bas@eliteworldhotels.com.tr</a>. Please <span className="b i">mention group code "Family Reunion 04-06 August"</span> to get our discounted room prices.<br />
      </p>
      <p>
        You can reach the <b>Kubba Reunion 2023 committee</b> by email at <a className="cool-purple" href="mailto:kr22@kubbareunion.com">kr22@kubbareunion.com</a>.
      </p>
      <p className="mt4">
        Of course, the very best way to reach the committee is to join us in Turkey in 2023. We hope to see you there!
      </p>
      <img src="family-photo-2016.jpg" alt="2016" className="br2 cool-shadow" style={{ filter: 'contrast(1.2) brightness(.9) saturate(1.1)' }} />
    </>
  )
}

const Components = {
  Welcome,
  Register,
  RegisterConfirm,
  RegisterThanks,
  Information,
  Contact,
}

function App() {
  const [names, setNames] = useState({ adults: [''], olderChildren: [], youngerChildren: [] });
  const [supportAmount, setSupportAmount] = useState(0);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsMenuVisible(() => false));
  const [current, _setCurrent] = useState('Welcome');
  const setCurrent = x => {
    window.scroll({top: 0, behavior: 'smooth'})
    return _setCurrent(x);
  }
  const Component = Components[current] || (() => (<></>));

  const openMenu = () => setIsMenuVisible(v => {
    if (!v) window.scroll({top: 0, behavior: 'smooth'})
    return !v;
  });

  return (
    <div className="flex flex-column items-center pt4-l">
      <div className="w-100 mw8 flex flex-column ">
        <div className="mt4-l center w-100 mw8 relative z-3 bg-mid-gray" style={{ height: '10.5rem' }}>
          <div className="absolute right-2" style={{ top: '3.55rem' }}>
            <img src="logo-opt.png" alt="KR 2023 Logo" className="cool-shadow w5 dn-l" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()} onClick={openMenu} />
            <img src="logo-opt.png" alt="KR 2023 Logo" className="cool-shadow w5 dn db-l" />
          </div>
          <div className="absolute left-2 bottom-1 dn db-m dn-l">
            <div className="f3 moon-gray black-shadow pointer" onClick={openMenu}>☰ Menu</div>
          </div>
          <div className="absolute left-2 bottom-1 dn flex-l justify-between" style={{ width: '36rem' }}>
            <div className="f3 moon-gray black-shadow pointer" onClick={() => setCurrent('Welcome')}>Welcome</div>
            <div className="f3 moon-gray black-shadow pointer" onClick={() => setCurrent('Register')}>Register</div>
            <div className="f3 moon-gray black-shadow pointer" onClick={() => setCurrent('Information')}>Information</div>
            <div className="f3 moon-gray black-shadow pointer" onClick={() => setCurrent('Contact')}>Contact</div>
          </div>
          <div ref={ref}>
            <div className={ `${ isMenuVisible ? 'fixed db dn-ns' : 'dn' } top-2 left-0 right-2 bg-cool-purple white cool-shadow` }>
              <Menu setCurrent={setCurrent} setIsMenuVisible={setIsMenuVisible} />
            </div>
            <div className={ `${ isMenuVisible ? 'fixed dn db-m' : 'dn' } top-2 left-0 bg-cool-purple w-50 white cool-shadow` }>
              <Menu setCurrent={setCurrent} setIsMenuVisible={setIsMenuVisible} />
            </div>
          </div>
        </div>
        <div className="center w-100 mw8 relative b--cool-border bw1 ba cool-shadow bg-gray nt1 z-2 pl4" style={{ height: '5.35rem', paddingTop: '1.5rem' }}>
          <div className="cool-purple white-shadow dn db-ns f2 b">Kubba Reunion 2023</div>
          <div className="absolute left-2 bottom-1 dn-ns pb2 z-4">
            <div className="f3 moon-gray black-shadow pointer" onClick={openMenu}>☰ Menu</div>
          </div>
        </div>
        <div className="center w-100 mw8 b--cool-border bw1 ba cool-shadow bg-silver nt1 z-1 tc" style={{ height: '5.35rem', paddingTop: '1.5rem' }}>
          <div className="cool-purple white-shadow dn-ns f2 b">Kubba Reunion 2023</div>
        </div>
        <div className="center w-100 mw8 b--cool-border bw1 ba cool-shadow bg-moon-gray nt1 pa3 pa4-ns pb7 pb7-ns">

          <Component setCurrent={setCurrent} names={names} setNames={setNames} supportAmount={supportAmount} setSupportAmount={setSupportAmount} />

          {
            (current === 'Register' || current === 'RegisterConfirm') ? null : (
              <>
                <p className="mt5"><b>Helpful Links</b></p>
                <p>
                  <span className="pointer cool-purple underline" onClick={() => setCurrent('Information')}>More information about the event</span>
                </p>
                <p>
                  <span className="pointer cool-purple underline" onClick={() => setCurrent('Register')}>Register for the event</span>
                </p>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
  /*
          <div className="cool-shadow br2-ns pa2 f5 f4-ns flex justify-around" style={{ backgroundColor: '#fffbf4' }}>
            <div className="cool-blue">
              Welcome
            </div>
            <div className="b cool-blue">
              Register
            </div>
            <div className="cool-blue">
              Information
            </div>
            <div className="cool-blue">
              Contact
            </div>
          </div>
        </div>
        <div className="mt4 center w-100 mw8 cool-shadow pa4 b--white bw1 ba">
          <p>Dear family,</p>
          <p>We are thrilled to announce the final details and begin registration for the 2023 Kubba Reunion!</p>
          <p>The next reunion will take place between <b>August 4 - 6, 2023</b>, at the <b>Elite World Hotel</b> in <b>Sapanca, Turkey</b>.</p>
          <img src="elite-world-sapanca.jpg" />
          <p>The Kubba family reunion is a gathering for descendents of Hajj Ma’roof Kubba, and relatives with parents or grandparents from Kubba family.</p>
          <p>We hope you can all come and join us for another wonderful family gathering!</p>
          <p></p>
        </div>
  */

}

export default App;
